import React, { ReactNode, useState } from 'react'

// Assets
import ExitIcon from 'src/assets/images/icons/icon-exit.svg'
import SearchIcon from 'src/assets/images/icons/icon-search.svg'

// components
import GeneralSearch from 'src/components/GeneralSearch'

// Style
import { General, SearchButton } from './style'

type TabItemProps = {
  name: string;
  slug: string;
}

type DefaultTabProps = {
  className?: string;
  items: TabItemProps[];
  children: ReactNode[];
}

const Select = ({ items, className, children }: DefaultTabProps) => {
  const [ activeTab, setActiveTab ] = useState(0)
  const [ search, setSearch ] = useState(false)

  function handleChange (event: React.ChangeEvent<HTMLSelectElement>) {
    setActiveTab(parseInt(event.target.value))
  }

  return (
    <div className={`position-relative w-100 col-md-12 ${className}`}>
      <General id='search-form' className='col-12 col-md-10 px-0 d-flex mb-md-4'>
        <span className='select'>
          <select className='fs-16 fw-400 border-0' onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange(e)}>
            <option value=''>Busque por categoria</option>
            {items.map((item: TabItemProps, index: number) => (
              <option selected={index === 0} value={index} key={index}>
                {item.name}
              </option>
            ))}
          </select>
        </span>

        { search && <GeneralSearch styles='position-absolute mt-5' /> }

        <SearchButton onClick={() => setSearch(!search)}>
          {
            search
              ? <img src={ExitIcon} alt='Fechar' title='Fechar' />
              : <img src={SearchIcon} alt='Buscar' title='Buscar' />
          }
        </SearchButton>
      </General>

      <div>
        {
          children.map((item: ReactNode, index: number) => (
            <div className={`${activeTab === index ? 'd-block' : 'd-none'}`} key={index}>{item}</div>
          ))
        }
      </div>
    </div>
  )
}

export default Select
