import styled from 'styled-components'
import breakpoints from '../styles/breakpoints'
import { grayscale, white } from '../styles/colors'

export const FormCenterHome = styled.section`

  .row {
    background: ${grayscale['100']};
    padding: 32px 14px;
    margin: 0 13px;
  }

  input {
    background: ${white} !important;
  }
  
  .pt-form-center {
    padding-top: 0 !important;
  }

  .form-center {
    display: none;
  }
`

export const BoxCard = styled.div`

  @media(min-width: ${breakpoints.md}) {
    &.bg-white {
      background: none !important;
      border: none !important;
      min-height: 190px;

      &.mude-pro-nter {
        min-height: 205px;
      }     
    }

    .bg-white {
      min-height: 200px;
     
      &.voce-por-dentro {
        min-height: 140px;
      }
    }
  }

  @media(min-width: ${breakpoints.lg}) {
    .bg-white {
      min-height: 230px;
    }
  }

  @media(min-width: ${breakpoints.xl}) {
    .bg-white {
      min-height: 280px;   
    }
  } 
`

export const FormBottom = styled.section`
  background: ${grayscale['100']};

  @media(min-width: ${breakpoints.lg}) {
    background: ${white};

    .container {
      border-top: 1px solid ${grayscale['200']};
      padding: 96px 0 90px;
    }
  }
`

export const GridContainer = styled.div`
  display: none;

  @media(min-width: ${breakpoints.sm}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 24px
  }
`

export const NoticiasEmDestaqueSection = styled.section`
  height: 735px;
  
  @media (min-width: ${breakpoints.md}) {
    height: 480px;
  }
  @media (min-width: ${breakpoints.lg}) {
    height: 575px;
  }
`
