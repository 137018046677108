import { Link } from 'gatsby'
import breakpoints from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled, { css } from 'styled-components'

type TopNewsItemStyleProps = {
  active?: boolean;
}

export default styled(Link)<TopNewsItemStyleProps>`
  padding: 15px 0;
  border-radius: 16px;
  transition: all 0.3s ease-in-out;
  display: block;
  text-decoration: none !important;
  
  @media (min-width: ${breakpoints.lg}) {
    padding: 18px 0;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    margin: 8px;
    padding: 18px 20px;

    ${(props: TopNewsItemStyleProps) => props.active && css`
      background-color: ${grayscale[100]};
    `}
  }

  h2 {

    @media (min-width: ${breakpoints.lg}) {
      height: 50px;
      overflow: hidden;
    }
  }

  svg {
    margin-left: 7px;
  }
`
