import styled from 'styled-components'
import { orange } from 'src/styles/colors'

export const Container = styled.div`
  position: relative;

  .react-multi-carousel-track {
    padding: 30px 0 50px;
  }

  /* Arrows */
  .react-multiple-carousel__arrow {
    bottom: -8px;
    outline: none;
    z-index: 2;
    min-height: 22px;
    min-width: 22px;
    bottom: 0;
    background-color: ${orange.base};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &:disabled {
      cursor: default;
      background-color: #fff7f0;
      z-index: 1;
    }

    &--right {
      left: 190px;
      right: auto;
    }

    &--left {
      left: 0;
    }

    &::before {
      content: none;
    }
  }
`

type ProgressBarProps = {
  progress: number;
}

export const ProgressBar = styled.div<ProgressBarProps>`
  text-align: left;
  background-color: rgba(255, 122, 0, 0.3);
  border-radius: 100px;
  height: 4px;
  margin: 0 10px 0 10px;
  width: 135px;
  position: absolute;
  bottom: 8px;
  left: 35px;

  > span {
    display: block;
    background-color: ${orange.base};
    border-radius: 100px;
    height: 4px;
    opacity: 1.0;
    transition: all 0.5s ease;
    width: ${(props: ProgressBarProps) => `${props.progress}%`};
  }
`

export const Counter = styled.span`
  position: absolute;
  left: 240px;
  bottom: 0;
  font-size: 14px;
`
