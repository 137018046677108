export const HandleScroll = (id: string) => {
  const section = document.getElementById(id)
  const position = section?.offsetTop || 0
  const poss = position - 187

  window.scroll({
    top: poss,
    behavior: 'smooth',
  })
}
