import styled from 'styled-components'
import { gray, grayscale, green, orange, white } from 'src/styles/colors'
import breakpoints from 'src/styles/breakpoints'
import { Theme } from 'src/types/Theme'

type FormProps = {
  theme: Theme;
}

export const Form = styled.form<FormProps>`

  label {
    color: ${grayscale['400']};
    display: block;
  }

  input {
    background: ${white};
    border-radius: 8px;
    border: none;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 15px;
    font-weight: 300;
    color: ${gray[600]};
    width: 100%;

    @media(min-width: ${breakpoints.lg}) {
      background: ${grayscale['100']};
    }

    &.hasError {
      border: 1px solid red;
    }
  }

  .field--label-error {
    font-size: 12px;
    color: red;
  }

  .custom-radio {

    .radio {
      &:not(:last-child) {
        border-bottom: 1px solid #E6E7F0;
      }
    }

    .radio > label{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 10px;
      font-size: 14px;
      position: relative;
      cursor: pointer;

      input {
        margin-right: 10px;
        opacity: 0;
      }

      .help {
        display: block;
        width: 20px;
        height: 20px;
        position: relative;
      }

      .help::before {
        display: block;
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        border: 2px solid ${orange.base};
        border-radius: 50%;
        top: 2px;
        left: 0;
      }

      input:checked + .help::after {
        display: block;
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        background-color: ${orange.base};
        border-radius: 50%;
        left: 4px;
        top: 6px;
        opacity: 1;
      }
    }
  }

  .checkbox {

    label {
      position: relative;
      padding-left: 30px;
      margin-bottom: 0;

      &:before, &:after {
        display: block;
        position: absolute;
        content: '';
      }

      &:before {
        border: 2px solid ${grayscale['400']};
        border-radius: 3px;
        width: 20px;
        height: 20px;
        left: 0;
        top: 2px;
      }
    }

    input {
      width: auto;
      margin-right: 10px;
      opacity: 0;
      height: 0;

      &:disabled ~ label {
        opacity: 0.6;
      }

      &:checked ~ label {

        &:before {
          border: 2px solid ${orange.base};
          background-color: ${({ theme }: FormProps) => theme === 'pj' ? green[500] : orange.base};
        }

        &:after {
          left: 7px;
          top: 5px;
          width: 6px;
          height: 10px;
          border: solid ${white};
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
`

export const Msg = styled.div`

  h4 {
    color: ${gray[800]};
  }

  p {
    color: ${gray[600]};
  }
`
