import styled from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'
import { borderRounded } from 'src/styles/radius'

export const General = styled.div`
  
  span.select {
    background: ${grayscale['100']};
    border-radius: ${borderRounded['8']};
    display: block;
    position: relative;
    outline: none;
    overflow: hidden;
    width: calc(100% - 45px);

    &:after {
      content: "";
      border-color: transparent transparent ${orange.base} ${orange.base};
      border-style: solid;
      border-width: 2px;
      height: 9px;
      margin-top: -7px;
      padding: 0;
      pointer-events: none;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: rotate(315deg);
      transition: all .2s ease-in-out;
      width: 9px;
    }
    
    select {
      appearance: none;
      background: ${grayscale['100']}; 
      border-radius: ${borderRounded['8']};
      cursor: pointer;
      color: ${orange.base};
      font-size: 14px;
      padding: 10px;
      width: 100%;
      height: 42px;
      outline: none;
    }
  }  
`
export const SearchButton = styled.button`
  border: none;
  background: ${white};
  cursor: pointer;
  height: 35px;
  width: 35px;
  flex: 0 0 auto;
  padding: 0;
  margin-top: 4px;
  margin-left: 10px;

  &:focus {
    outline: 0px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`
