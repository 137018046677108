import styled, { css } from 'styled-components'
import { white } from 'src/styles/colors'
import GeneralSearchStyle from '../GeneralSearch/style'

type ContainerStyleProps = {
  search: boolean;
}

export const Container = styled.div<ContainerStyleProps>`
  display: flex;
  align-items: flex-start;
  position: relative;

  .tab-blog-articles {

    > div {
      margin-top: 45px;
    }

    ${(props: ContainerStyleProps) => props.search && (
    css`
      > div {
        padding-top: 75px;
      }
    `
  )}

  ${GeneralSearchStyle} {
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    margin-top: 0;
  }
`

export const SearchButton = styled.button`
  border: none;
  background: ${white};
  cursor: pointer;
  height: 35px;
  width: 35px;
  flex: 0 0 auto;
  padding: 0;
  position: relative;
  right: 35px;

  &:focus {
    outline: 0px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`
