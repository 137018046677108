/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from '@reach/router'

import { sendCDPFormData } from 'src/shared/cdp'

import Message from '../Newsletter/Message'

import { Button } from 'src/styles/button'
import { Form } from './style'
import { sendNewsletterFrom } from 'src/shared/helpers'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'

type NewsletterHomeCenterProps = {
  name: string;
}

const NewsletterHomeCenter = ({ name }: NewsletterHomeCenterProps) => {
  const { register, errors, handleSubmit }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ acceptedTerms, setAcceptedTerms ] = useState(false)
  const [ status, setStatus ] = useState('')
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  function handleCheck (event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAcceptedTerms(inputValue)
  }

  const sendForm = async (data: Record<string, string>) => {
    setLoading(true)
    const success = await sendNewsletterFrom(data.nomeCompleto, data.email)
    sendCDPFormData(
      {
        formName: 'newsletter-blog',
        cpf: '',
        email: data.email,
        tituloPagina: document.title,
        categoria: '',
        subCategoria: '',
      },
    )
    if (success) {
      setStatus('success')
    } else {
      setStatus('error')
    }
    setLoading(false)

    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: 'Home',
        sub_section: 'Assine e receba nossos conteúdos exclusivos.',
        element_action: 'submit',
        element_name: 'Assine e receba nossos conteúdos exclusivos.',
        c_page: location.href,
        redirect_url: 'null',
        segment: 'Home',
        sub_segment: 'null',
      },
    })
  }

  return (
    status !== '' ? <Message status={status} setStatus={setStatus} />
      : <Form onSubmit={handleSubmit(sendForm)}>
        <div className='col-12 pl-0 d-flex'>
          <div className='col-5 pl-0'>
            <label htmlFor='nomeCompleto' className='fs-14 fw-600 w-100 mb-1'>Nome</label>
            <input
              ref={register({
                required: 'O nome é obrigatório',
              })}
              name='nomeCompleto'
              id='nomeCompleto'
              type='text'
              placeholder='Digite seu nome completo'
            />
            { errors.nomeCompleto && <p className='fs-12 text-red-base my-2'>{errors.nomeCompleto.message}</p> }
          </div>
          <div className='col-5'>
            <label htmlFor='email' className='fs-14 fw-600 w-100 mb-1'>E-mail</label>
            <input
              ref={register({
                required: 'E-mail obrigatório',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'E-mail inválido',
                },
              })}
              name='email'
              id='email'
              type='text'
              placeholder='Informe o seu e-mail'
            />
            { errors.email && <p className='fs-12 text-red-base my-2'>{errors.email.message}</p> }
          </div>
          <div className='col-2 mt-4 pr-0'>
            <Button
              type='submit'
              title='Assinar'
              disabled={!acceptedTerms || loading}
              className='w-100'
            >
              {loading ? 'Enviando...' : 'Assinar'}
            </Button>
          </div>
        </div>
        <div className='col-lg-12 col-xl-10 pl-0 checkbox'>
          <input
            ref={register()}
            id={name}
            name={name}
            type='checkbox'
            onChange={handleCheck}
          />
          <label className='formLabelCheck fs-12 lh-16 text-grayscale-400' htmlFor={name}>
            Autorizo o envio de conteúdo sobre investimentos, educação financeira e novos produtos financeiros e estou de acordo com a
            <a
              className='fw-700' title='Acessar Política de Privacidade do Banco Inter'
              href='https://www.bancointer.com.br/politica-de-privacidade/'
              target='_blank' rel='noreferrer'
            > Política de Privacidade
            </a> do Inter.
          </label>
        </div>
      </Form>
  )
}

NewsletterHomeCenter.defaultProps = {
  name: 'aceite-termos',
}

export default NewsletterHomeCenter
