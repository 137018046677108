import React from 'react'

import Success from 'src/assets/images/success.png'
import Error from 'src/assets/images/error.png'

import { Msg } from './style'

const objectPig = {
  success: Success,
  error: Error,
}
interface IMessageProps {
  status: string;
  setStatus: (value: string) => void;
}
interface IInfo {
  [params: string]: {
    title: string;
    description: string;
    button: string;
    handleCallbackButton: () => void;
   };
}

const Message = ({ status, setStatus }: IMessageProps) => {
  const info: IInfo = {
    success: {
      title: 'Obrigado por se cadastrar!',
      description: 'O material será enviado para o seu e-mail',
      button: 'Ok, entendi',
      handleCallbackButton: () => setStatus(''),
    },
    error: {
      title: 'No momento não foi possível enviar o formulário',
      description: 'Por favor, tente novamente mais tarde',
      button: 'Ok, entendi',
      handleCallbackButton: () => setStatus(''),
    },
  }

  return (
    <Msg className='col-12 text-center pt-4 pt-form-center'>
      <div className='col-12 my-3 form-center'>
        <img src={objectPig[status]} alt={info[status].title} />
      </div>
      <h4 className='fs-18 lh-24 mb-2 fw-600'>{info[status].title}</h4>
      <p className='fs-14 lh-20 pt-3 pt-form-center mb-4'>
        {info[status].description}
      </p>
      <button
        className='btn btn-lg bg-orange--base text-white text-uppercase fw-600 fs-14 lh-17'
        title={info[status].button}
        onClick={info[status].handleCallbackButton}
      >{info[status].button}
      </button>
    </Msg>
  )
}

export default Message
