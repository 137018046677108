import React, { useEffect, useRef, useState, ReactNode, RefObject } from 'react'
import Carousel from 'react-multi-carousel'
import ArrowLeft from 'inter-frontend-svgs/lib/v2/navigation/chevron-left'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'

import RightArrow from './RightArrow'
import LeftArrow from './LeftArrow'

import { Container, ProgressBar, Counter } from './style'

type CarouselBreakpoints = {
  items?: number;
  slidesToSlide?: number;
  partialVisibilityGutter?: number;
}

type HeaderCarouselProps = {
  children: ReactNode[];
  sm: CarouselBreakpoints;
  md: CarouselBreakpoints;
  lg: CarouselBreakpoints;
  xl: CarouselBreakpoints;
  containerClass?: string;
  activeItem?: number;
}
function HeaderCarousel ({ children, sm, md, lg, xl, containerClass, activeItem }: HeaderCarouselProps) {
  const CarouselRef = useRef<Carousel | unknown>()
  const [ progress, setProgress ] = useState(50)
  const [ activeItemSlider, setActiveItemSlider ] = useState(0)
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: xl.items,
      slidesToSlide: xl.slidesToSlide,
      partialVisibilityGutter: xl.partialVisibilityGutter,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: lg.items,
      slidesToSlide: lg.slidesToSlide,
      partialVisibilityGutter: lg.partialVisibilityGutter,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: md.items,
      slidesToSlide: md.slidesToSlide,
      partialVisibilityGutter: md.partialVisibilityGutter,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: sm.items,
      slidesToSlide: sm.slidesToSlide,
      partialVisibilityGutter: sm.partialVisibilityGutter,
    },
  }

  useEffect(() => {
    if (activeItem !== undefined) {
      CarouselRef.current.goToSlide(activeItem)
      setActiveItemSlider(activeItem)
    }
  }, [ activeItem ])

  useEffect(() => {
    setProgress(((activeItemSlider + 1) / children.length) * 100)
  }, [ activeItemSlider ])

  function moveProgressBar (nextSlide: number) {
    setActiveItemSlider(nextSlide)
  }

  function formatNumberToTwoDigits (number: number) {
    return number.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })
  }

  return (
    <Container>
      <Carousel
        ref={CarouselRef as RefObject<Carousel>}
        responsive={responsive}
        customRightArrow={<RightArrow />}
        customLeftArrow={<LeftArrow />}
        partialVisible={true}
        showDots={false}
        arrows={true}
        containerClass={containerClass}
        beforeChange={moveProgressBar}
      >
        {children}
      </Carousel>
      <button disabled type='button' className='border-0 rounded-100 react-multiple-carousel__arrow react-multiple-carousel__arrow--left'>
        <ArrowLeft width={15} height={15} color='orange--base' />
      </button>
      <ProgressBar progress={progress}><span /></ProgressBar>
      <button disabled type='button' className='border-0 rounded-100 react-multiple-carousel__arrow react-multiple-carousel__arrow--right'>
        <ArrowRight width={15} height={15} color='orange--base' />
      </button>
      {CarouselRef.current &&
        <Counter className='fs-18 text-grayscale--400'>
          <strong>{formatNumberToTwoDigits(activeItemSlider + 1)}</strong> / {formatNumberToTwoDigits(children.length)}
        </Counter>}
    </Container>
  )
}

HeaderCarousel.defaultProps = {
  sm: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  md: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  lg: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  xl: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
}

export default HeaderCarousel
