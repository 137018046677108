import breakpoints from 'src/styles/breakpoints'
import styled from 'styled-components'
import { Counter, ProgressBar } from '../HeaderCarousel/style'

export default styled.div`
  margin: 20px 0;

  img {
    width: 228px;
    height: 228px;
    object-fit: cover;
    max-width: 450px;
    border-radius: 50px;
    height: 228px;
    @media (min-width: ${breakpoints.lg}) {
      width: 450px;
      height: 450px;
    }
  }

  .react-multi-carousel-track {
    padding-top: 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin: 45px 0;
  
    ${ProgressBar} {
      left: 50%;
      transform: translateX(170px);
    }

  
    .react-multiple-carousel__arrow {
  
      &--left {
        left: 50%;
        transform: translateX(140px);
      }
  
      &--right {
        left: 50%;
        transform: translateX(330px);
      }
    }

    ${Counter} {
      left: 50%;
      transform: translateX(370px);
    }
  }
`
