import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'

import { IframeVideo } from './style'

import homeJSON from 'src/assets/data/home.json'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'

function Video () {
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  const handleClick = () => {
    if (document.activeElement.tagName === 'IFRAME') {
      sendDataLayer({
        event: 'ga_event_body_blog',
        params: {
          section: 'Dobra_03',
          sub_section: homeJSON.video_section_title,
          element_action: 'click video',
          element_name: '3 milhões de correntistas Banco Inter',
          c_page: location.href,
          redirect_url: 'null',
          segment: homeJSON.video_section_title,
          sub_segment: 'null',
        },
      })
    }
  }

  useEffect(() => {
    window.addEventListener('blur', handleClick)

    return () => {
      window.removeEventListener('blur', handleClick)
    }
  }, [])

  return (
    <div className='row justify-content-center align-items-center flex-md-column text-center text-md-left text-lg-center'>
      <div className='col-12 col-lg-8 pb-2 pb-md-4'>
        <h3 className='fs-24 lh-28 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-47 fw-600 text-grayscale--500 pb-4 pb-md-2'>
          {homeJSON.video_section_title}
        </h3>
        <div
          className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400'
          dangerouslySetInnerHTML={{ __html: homeJSON.video_section_introduction }}
        />
      </div>
      <div className='col-12 col-lg-10 col-xxl-8'>
        <IframeVideo
          src={homeJSON.video_section_url.replace('youtube.com', 'youtube-nocookie.com')}
          className='optanon-category-C0003'
          frameBorder='0'
          allow='autoplay'
          encrypted-media
          width='100%'
          allowFullScreen
        />
      </div>
    </div>
  )
}

export default Video
