import React from 'react'
import Tag from 'src/components/Tag'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import { useLocation } from '@reach/router'

import { TopNewsItemProps } from '../types'

import TopNewsItemStyle from './style'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'

const TopNewsItem = ({ active, category, subCategory, title, link, hover }: TopNewsItemProps) => {
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  const dataLayerExtract = () => {
    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: 'Dobra_01',
        sub_section: 'Top News',
        element_action: 'click button',
        element_name: title,
        c_page: location.href,
        redirect_url: `${location.href}${link}`,
        segment: category || 'null',
        sub_segment: subCategory || 'null',
      },
    })

    dataLayer.push({
      event: 'Abriu post',
      categoria: category,
      avenida: subCategory,
      origem: 'Hero',
    })
  }

  return (
    <TopNewsItemStyle active={active} to={link} onMouseEnter={hover} onClick={dataLayerExtract}>
      <Tag className='mr-3'>{category}</Tag>
      {subCategory && <Tag color='gray'>{subCategory}</Tag>}
      <h2 className='fs-16 fs-md-20 fs-lg-16 fs-xl-20 text-grayscale--500 fw-600 my-2'>{title}</h2>
      <span className='fs-14 text-orange--base'>
        Leia mais
        <ArrowRight width={21} height={21} color='orange--base' />
      </span>
    </TopNewsItemStyle>
  )
}

export default TopNewsItem
