/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'

import Layout from 'src/components/Layout'
import Icon from 'src/components/Icon'
import TopNews from 'src/components/TopNews'
import GeneralSearch from 'src/components/GeneralSearch/'
import BlogArticleList from 'src/components/BlogArticleList'
import Video from 'src/components/Video/'
import Newsletter from 'src/components/Newsletter'
import NewsletterHomeCenter from 'src/components/NewsletterHomeCenter'
import { clearLocation } from 'src/utils/clearLocation'
import { HandleScroll } from 'src/utils/handleScroll'

import TwitterIcon from 'src/assets/images/icons/icon-twitter.svg'
import PagamentosBaixa from 'src/assets/images/pagamentos-baixa.svg'
import InstagramIcon from 'src/assets/images/icons/icon-Instagram.svg'
import TikTokIcon from 'src/assets/images/icons/icon-tiktok.svg'
import ArrowRight from 'src/assets/images/icons/icon-arrow-right.svg'
import { useLocation } from '@reach/router'

import youInsideTheMarketJSON from 'src/assets/data/youInsideTheMarket.json'
import pageContext from './pageContext.json'

import { FormCenterHome, FormBottom, BoxCard, GridContainer, NoticiasEmDestaqueSection } from './style'

import { youInsideTheMarketProps } from './types'
import SEO from 'src/components/Seo'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'
import useWidthResize from 'src/hooks/useWidth'
const WIDTH_MD = 768

const Home = () => {
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()
  const icons = { PagamentosBaixa, TwitterIcon, InstagramIcon }
  const width = useWidthResize()
  const dataLayer = (values: any) => {
    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: 'Dobra_04',
        sub_section: values.sub_section,
        element_action: 'click button',
        element_name: values.element_name,
        c_page: location.href,
        redirect_url: values.redirect_url,
        segment: values.segment,
        sub_segment: 'null',
      },
    })
  }

  useEffect(() => {
    const location = clearLocation(window.location.href)

    if (location) {
      setTimeout(() => HandleScroll(location), 100)
    }
  }, [])

  return (
    <Layout>
      <SEO
        title={pageContext.metaTitle}
        url={process.env.BASE_URL}
        meta={[
          {
            name: 'description',
            content: pageContext.metaDescription,
          },
          {
            property: 'og:title',
            content: pageContext.metaTitle,
          },
          {
            property: 'og:description',
            content: pageContext.metaDescription,
          },
          {
            property: 'og:locale',
            content: 'pt_BR',
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:url',
            content: `${process.env.BASE_URL}`,
          },
          {
            property: 'og:site_name',
            content: 'Blog do Inter',
          },
          {
            property: 'twitter:site',
            content: '@bancointer',
          },
          {
            property: 'twitter:creator',
            content: '@bancointer',
          },
        ]}
      />
      <h1 className='sr-only'>Blog do Inter</h1>
      <section id='buscar'>
        <GeneralSearch />
      </section>

      <NoticiasEmDestaqueSection id='noticias-em-destaque'>
        <div className='container'>
          <TopNews />
        </div>
      </NoticiasEmDestaqueSection>

      {width > WIDTH_MD &&
        <FormCenterHome id='form-newsletter-center' className='pt-3 pb-5 pb-lg-0'>
          <div className='container mb-5'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-xl-4 d-flex d-xl-block mb-4 align-items-center'>
                <div className='col-12 col-lg-1 mb-xl-2 px-0'>
                  <Icon width='35' height='35' color='grayscale-500' icon='action/mail' directory='v2' />
                </div>
                <div className='col-12 col-lg-9 px-0'>
                  <h3 className='fs-20 lh-24 fw-600 text-grayscale-500 mb-0'>
                    Assine e receba nossos conteúdos exclusivos.
                  </h3>
                </div>
              </div>
              <div className='col-11 offset-lg-1 col-xl-8 offset-xl-0'>
                <NewsletterHomeCenter name='form-home-center' />
              </div>
            </div>
          </div>
        </FormCenterHome>
      }

      <section id='artigos-do-blog'>
        <BlogArticleList />
      </section>

      <section id='canal-de-video' className='bg-white pt-5'>
        <div className='container'>
          <Video />
        </div>
      </section>

      <section id='como-investir' className='bg-white pt-4 pb-5'>
        <div className='container py-xl-4'>
          <div className='row justify-content-center align-items-center justify-content-md-between'>
            <div className='col-12 col-lg-5 mx-auto ml-lg-0 mb-3 mb-md-4'>
              <h3 className='fs-24 lh-28 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-47 fw-600 text-grayscale--500 pt-2'>
                <span className='d-block d-md-inline'>Você por dentro do</span> mercado
              </h3>
              <p className='fs-18 lh-22 text-grayscale--400 mb-0'>
                Mantenha-se informado com os nossos relatórios econômicos Inter Research e saiba tudo sobre o mercado acompanhando nossas redes sociais.
              </p>
            </div>
            <div className='col-12 col-lg-6 px-0'>
              {
                youInsideTheMarketJSON.map((item: youInsideTheMarketProps, index: number) => (
                  <BoxCard className='col-12 mx-auto pt-3 pb-2' key={index}>
                    <div className='d-flex flex-column pl-4 pr-3 pt-4 pb-2 py-md-4 pl-lg-3 bg-white border rounded-16 justify-content-center voce-por-dentro'>
                      <div className='row mx-0'>
                        <div className='col-12 col-md-5 col-lg-6 align-self-end px-lg-0'>
                          <div className='d-flex'>
                            <img src={icons[item.icon]} alt={item.title} className='mb-3 mr-3' />
                            <span className='text-orange--base fs-16 lh-20 mb-3 fw-600'>{item.title}</span>
                          </div>
                          <h3 className='fs-16 lh-18 pb-1 fw-600 text-grayscale--500 mb-0'>
                            {item.description}
                          </h3>
                        </div>
                        <div className='col-12 col-md-7 col-lg-6 mt-2 align-self-end px-lg-0'>
                          <a
                            href={item.url}
                            title='Veja nossos relatórios'
                            rel='noreferrer'
                            className='fs-14 lh-17 fw-400 text-orange--base float-right'
                            onClick={() => dataLayer({
                              sub_section: 'Você por dentro do mercado',
                              element_name: item.buttonText,
                              redirect_url: item.url,
                              segment: item.title,
                            })}
                            target='_blank'
                          >
                            {item.buttonText}
                            <Icon width='27.12' height='24' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                          </a>
                        </div>
                      </div>
                    </div>
                  </BoxCard>
                ))
              }
            </div>
          </div>
        </div>
      </section>

      {width <= WIDTH_MD &&
        <FormBottom id='form-newsletter-bottom' className='pt-4 pb-5 pt-md-5 py-lg-0'>
          <div className='container py-md-3' id='newsletter'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-lg-6'>
                <div className='row mx-md-0'>
                  <div className='col-12 col-md-1 col-lg-12 px-0 px-lg-2'>
                    <Icon width='35' height='35' color='grayscale-500' icon='action/mail' directory='v2' />
                  </div>
                  <div className='col-12 col-md-11 pt-3 pt-md-0 pt-lg-4 px-0 px-lg-2'>
                    <h3 className='fs-24 lh-28 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-47 fw-600 text-grayscale-500'>
                      Assine e receba nossos conteúdos exclusivos.
                    </h3>
                    <p className='fs-18 lh-22 text-grayscale-400 mb-0'>
                      Receba conteúdos completos sobre investimentos, educação financeira e novos produtos.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-6 pt-4 pt-lg-0 px-2 px-lg-3'>
                <Newsletter name='form-home-bottom' />
              </div>
            </div>
          </div>
        </FormBottom>
      }

      <div className='py-5 pt-lg-0'>
        <div className='container pb-md-5'>
          <h2 className='w-100 text-left text-md-center fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50'>Redes Sociais</h2>
          <p className='fs-18 lh-22 text-left text-md-center text-grayscale--500 mb-4 mb-md-5'>
            Siga as nossas redes sociais e acompanhe todas as nossas novidades
          </p>
          <div className='row d-block d-sm-none'>
            <a
              href='https://www.instagram.com/interbr'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Siga nosso Instagram',
                  c_page: location.href,
                  redirect_url: 'https://www.instagram.com/interbr',
                  segment: 'Instagram',
                  sub_segment: 'null',
                },
              })}
              className='col-12 d-flex align-items-center justify-content-between border-bottom mb-3 pb-3 text-grayscale--500'
            >
              <span className='fs-12 lh-15 font-weight-bold'>Instagram</span>
              <img src={InstagramIcon} alt='Instagram Logo' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Acompanhe nosso Twitter',
                  c_page: location.href,
                  redirect_url: 'https://twitter.com/interbr',
                  segment: 'Twitter',
                  sub_segment: 'null',
                },
              })}
              href='https://twitter.com/interbr'
              className='col-12 d-flex align-items-center justify-content-between border-bottom mb-3 pb-3 text-grayscale--500'
            >
              <span className='fs-12 lh-15 font-weight-bold'>Twitter</span>
              <img src={TwitterIcon} alt='Twitter Logo' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Acesse nosso LinkedIn',
                  c_page: location.href,
                  redirect_url: 'https://www.linkedin.com/company/inter',
                  segment: 'LinkedIn',
                  sub_segment: 'null',
                },
              })}
              href='https://www.linkedin.com/company/inter'
              className='col-12 d-flex align-items-center justify-content-between border-bottom mb-3 pb-3 text-grayscale--500'
            >
              <span className='fs-12 lh-15 font-weight-bold'>LinkedIn</span>
              <Icon width='25' height='25' color='orange--base' icon='social/new-linkedin' directory='v2' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Inscreva-se no nosso canal',
                  c_page: location.href,
                  redirect_url: 'https://www.youtube.com/@interbr',
                  segment: 'YouTube',
                  sub_segment: 'null',
                },
              })}
              href='https://www.youtube.com/@interbr'
              className='col-12 d-flex align-items-center justify-content-between border-bottom mb-3 pb-3 text-grayscale--500'
            >
              <span className='fs-12 lh-15 font-weight-bold'>YouTube</span>
              <Icon width='25' height='25' color='orange--base' icon='social/new-youtube' directory='v2' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Nos acompanhe no TikTok',
                  c_page: location.href,
                  redirect_url: 'https://www.tiktok.com/@interbr',
                  segment: 'TikTok',
                  sub_segment: 'null',
                },
              })}
              href='https://www.tiktok.com/@interbr'
              className='col-12 d-flex align-items-center justify-content-between border-bottom mb-3 pb-3 text-grayscale--500'
            >
              <span className='fs-12 lh-15 font-weight-bold'>TikTok</span>
              <img src={TikTokIcon} alt='TikTok Logo' />
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Siga a nossa página',
                  c_page: location.href,
                  redirect_url: 'https://www.facebook.com/interbrasil',
                  segment: 'Facebook',
                  sub_segment: 'null',
                },
              })}
              href='https://www.facebook.com/interbrasil'
              className='col-12 d-flex align-items-center justify-content-between border-bottom mb-3 pb-3 text-grayscale--500'
            >
              <span className='fs-12 lh-15 font-weight-bold'>Facebook</span>
              <Icon width='25' height='25' color='orange--base' icon='social/new-facebook' directory='v2' />
            </a>
          </div>
          <GridContainer>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Siga nosso Instagram',
                  c_page: location.href,
                  redirect_url: 'https://www.instagram.com/interbr',
                  segment: 'Instagram',
                  sub_segment: 'null',
                },
              })}
              href='https://www.instagram.com/interbr'
              className='col-12 border rounded-16 p-2 p-lg-4 d-flex align-items-center justify-content-between'
            >
              <div className='d-flex align-items-center'>
                <img src={InstagramIcon} alt='Instagram Logo' />
                <span className='fs-16 lh-19 text-orange--base font-weight-bold pl-2'>Instagram</span>
              </div>
              <div className='d-flex align-items-center'>
                <span className='fs-14 lh-17 text-orange--base pr-2'> Siga nosso Instagram</span>
                <img src={ArrowRight} alt='Seta de acesso' />
              </div>
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Acompanhe nosso Twitter',
                  c_page: location.href,
                  redirect_url: 'https://twitter.com/interbr',
                  segment: 'Twitter',
                  sub_segment: 'null',
                },
              })}
              href='https://twitter.com/interbr'
              className='col-12 border rounded-16 p-2 p-lg-4 d-flex align-items-center justify-content-between'
            >
              <div className='d-flex align-items-center'>
                <img src={TwitterIcon} alt='Instagram Logo' />
                <span className='fs-16 lh-19 text-orange--base font-weight-bold pl-2'>Twitter</span>
              </div>
              <div className='d-flex align-items-center'>
                <span className='fs-14 lh-17 text-orange--base pr-2'>Acompanhe nosso Twitter</span>
                <img src={ArrowRight} alt='Seta de acesso' />
              </div>
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Acesse nosso LinkedIn',
                  c_page: location.href,
                  redirect_url: 'https://www.linkedin.com/company/inter',
                  segment: 'LinkedIn',
                  sub_segment: 'null',
                },
              })}
              href='https://www.linkedin.com/company/inter'
              className='col-12 border rounded-16 p-2 p-lg-4 d-flex align-items-center justify-content-between'
            >
              <div className='d-flex align-items-center'>
                <Icon width='25' height='25' color='orange--base' icon='social/new-linkedin' directory='v2' />
                <span className='fs-16 lh-19 text-orange--base font-weight-bold pl-2'>LinkedIn</span>
              </div>
              <div className='d-flex align-items-center'>
                <span className='fs-14 lh-17 text-orange--base pr-2'>Acesse nosso LinkedIn</span>
                <img src={ArrowRight} alt='Seta de acesso' />
              </div>
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Inscreva-se no nosso canal',
                  c_page: location.href,
                  redirect_url: 'https://www.youtube.com/@interbr',
                  segment: 'YouTube',
                  sub_segment: 'null',
                },
              })}
              href='https://www.youtube.com/@interbr'
              className='col-12 border rounded-16 p-2 p-lg-4 d-flex align-items-center justify-content-between'
            >
              <div className='d-flex align-items-center'>
                <Icon width='25' height='25' color='orange--base' icon='social/new-youtube' directory='v2' />
                <span className='fs-16 lh-19 text-orange--base font-weight-bold pl-2'>YouTube</span>
              </div>
              <div className='d-flex align-items-center'>
                <span className='fs-14 lh-17 text-orange--base pr-2'>Inscreva-se no nosso canal</span>
                <img src={ArrowRight} alt='Seta de acesso' />
              </div>
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Nos acompanhe no TikTok',
                  c_page: location.href,
                  redirect_url: 'https://www.tiktok.com/@interbr',
                  segment: 'TikTok',
                  sub_segment: 'null',
                },
              })}
              href='https://www.tiktok.com/@interbr'
              className='col-12 border rounded-16 p-2 p-lg-4 d-flex align-items-center justify-content-between'
            >
              <div className='d-flex align-items-center'>
                <img src={TikTokIcon} alt='TikTok Logo' />
                <span className='fs-16 lh-19 text-orange--base font-weight-bold pl-2'>TikTok</span>
              </div>
              <div className='d-flex align-items-center'>
                <span className='fs-14 lh-17 text-orange--base pr-2 '>Nos acompanhe no TikTok</span>
                <img src={ArrowRight} alt='Seta de acesso' />
              </div>
            </a>
            <a
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                event: 'ga_event_body_blog',
                params: {
                  section: 'Dobra_06',
                  sub_section: 'Redes Sociais',
                  element_action: 'click button',
                  element_name: 'Siga a nossa página',
                  c_page: location.href,
                  redirect_url: 'https://www.facebook.com/interbrasil',
                  segment: 'Facebook',
                  sub_segment: 'null',
                },
              })}
              href='https://www.facebook.com/interbrasil'
              className='col-12 border rounded-16 p-2 p-lg-4 d-flex align-items-center justify-content-between'
            >
              <div className='d-flex align-items-center'>
                <Icon width='25' height='25' color='orange--base' icon='social/new-facebook' directory='v2' />
                <span className='fs-16 lh-19 text-orange--base font-weight-bold pl-2'>Facebook</span>
              </div>
              <div className='d-flex align-items-center'>
                <span className='fs-14 lh-17 text-orange--base pr-2 '>Siga a nossa página</span>
                <img src={ArrowRight} alt='Seta de acesso' />
              </div>
            </a>
          </GridContainer>
        </div>
      </div>
      {/* <BannerNewsletter /> */}
    </Layout>
  )
}

export default Home
