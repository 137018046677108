import styled, { css } from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'

export const TabGroup = styled.ul`
  margin-bottom: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

type TabItemStyleProps = {
  active: boolean;
}

export const TabItem = styled.li<TabItemStyleProps>`
  margin: 0 12px;

  button {
    font-size: 12px;
    border-radius: 20px;
    border: 1px solid ${grayscale[200]};
    background: ${white};
    padding: 10px 15px;
    font-weight: normal;
    color: ${grayscale[500]};
    min-width: 70px;

    &:hover {
      background-color: ${grayscale[100]};
      color: ${grayscale[500]};
    }

    ${(props: TabItemStyleProps) => props.active && (
    css`
        background-color: ${orange.base};
        border: 1px solid ${orange.base};
        color: ${white};

        &:hover {
          background-color: ${orange.base};
          color: ${white};
        }
      `
  )}

    &:focus {
      outline: 0px;
    }
  }
`
