import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const IframeVideo = styled.iframe`
  min-height: 207px;

  @media(min-width: ${breakpoints.md}) {
    min-height: 362px;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    max-width: 770px;
    min-height: 433px;
  }
`
