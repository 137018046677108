import React, { ReactNode, useState } from 'react'

import { TabGroup, TabItem } from './style'

type TabItemProps = {
  name: string;
  slug: string;
}

type ButtonTabProps = {
  className?: string;
  items: TabItemProps[];
  children: ReactNode[];
}

const ButtonTab = ({ className, items, children }: ButtonTabProps) => {
  const [ activeTab, setActiveTab ] = useState(0)

  function handleClick (index: number) {
    setActiveTab(index)
  }

  return (
    <div className={`w-100 ${className}`}>
      <nav>
        <TabGroup>
          {items.map((item: TabItemProps, index: number) => (
            <TabItem active={activeTab === index} key={item.slug}><button onClick={() => handleClick(index)}>{item.name}</button></TabItem>
          ))}
        </TabGroup>
      </nav>
      <div className='mt-2'>
        {
          children.map((item: ReactNode, index: number) => (
            <div className={`${activeTab === index ? 'd-block' : 'd-none'}`} key={index}>{item}</div>
          ))
        }
      </div>
    </div>
  )
}

ButtonTab.defaultProps = {
  className: '',
}

export default ButtonTab
