import React, { useState, Fragment, useLayoutEffect } from 'react'
import { format, parseISO } from 'date-fns'

import ExitIcon from 'src/assets/images/icons/icon-exit.svg'
import SearchIcon from 'src/assets/images/icons/icon-search.svg'

import DefaultTab from 'src/components/Tabs/DefaultTab'
import ButtonTab from 'src/components/Tabs/ButtonTab'
import GeneralSearch from 'src/components/GeneralSearch'
import BlogArticle from 'src/components/BlogArticle'
import DefaultSelect from 'src/components/Select/DefaultSelect'

import useWidth from 'src/hooks/useWidth'

import { Category, SubCategory } from 'src/types/Category'

import blogArticlesJSON from 'src/assets/data/blog-articles.json'
import categoriesJSON from 'src/assets/data/categories.json'

import { Container, SearchButton } from './style'
import { Link } from 'gatsby'

type BlogArticleListProps = {
  className?: string;
}

type Article = {
  id: string;
  slug: string;
  title: string;
  introduction: string;
  category_name: string;
  subcategory_name: string;
  grid_image_url: string;
  grid_image_alt: string;
  grid_image_redirect: string;
  published_at: string;
  updated_at: string;
}

const WIDTH_MD = 768

const BlogArticleList = ({ className }: BlogArticleListProps) => {
  const [ search, setSearch ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(false)
  const windowWidth = useWidth(300)
  const articleCategory = categoriesJSON.map((category: Category) => {
    if (category.subcategories && category.subcategories.length) {
      return (
        <Fragment key={category.id}>
          <ButtonTab items={category.subcategories}>
            {
              category.subcategories.map((subCategory: SubCategory) => (
                <Fragment key={subCategory.id}>
                  <div className='row'>
                    {
                      blogArticlesJSON[category.slug] && blogArticlesJSON[category.slug][subCategory.slug] &&
                        blogArticlesJSON[category.slug][subCategory.slug].slice(0, isMobile ? 3 : 6).map((article: Article) => (
                          <div key={article.slug} className='col-12 col-md-6 col-lg-4'>
                            <BlogArticle
                              link={`/${article.slug}/`}
                              image={article.grid_image_url}
                              altImage={article.grid_image_alt}
                              category={article.category_name}
                              urlLink={article.grid_image_redirect}
                              subCategory={article.subcategory_name}
                              title={article.title}
                              date={format(parseISO(article.published_at), 'dd/MM/yyyy')}
                              description={article.introduction}
                            />
                          </div>
                        ))
                    }
                  </div>
                  <div className='d-flex justify-content-center my-4'>
                    <Link
                      to={`/${subCategory.slug}/`}
                      className='fs-16 fw-700 text-center text-decoration-none text-orange--base'
                    >
                      Ver todos os artigos
                    </Link>
                  </div>
                </Fragment>
              ))
            }
          </ButtonTab>
        </Fragment>
      )
    } else if (blogArticlesJSON[category.slug] && blogArticlesJSON[category.slug].unsubcategorized.length) {
      return (
        <Fragment key={category.id}>
          <div className='row'>
            {
              blogArticlesJSON[category.slug].unsubcategorized.slice(0, isMobile ? 3 : 6).map((article: Article) => (
                <div key={article.id} className='col-12 col-md-6 col-lg-4'>
                  <BlogArticle
                    link={`/${article.slug}/`}
                    image={article.grid_image_url}
                    category={article.category_name}
                    urlLink={article.grid_image_redirect}
                    subCategory={article.subcategory_name}
                    altImage={article.grid_image_alt}
                    title={article.title}
                    date={format(parseISO(article.published_at), 'dd/MM/yyyy')}
                    description={article.introduction}
                  />
                </div>
              ))
            }
          </div>
          <div className='d-flex justify-content-center my-4'>
            <Link
              to={`/${category.slug}/`}
              className='fs-16 fw-700 text-center text-decoration-none text-orange--base'
            >Ver todos os artigos
            </Link>
          </div>
        </Fragment>
      )
    }
  })

  useLayoutEffect(() => {
    setIsMobile(windowWidth <= WIDTH_MD)
  }, [ windowWidth ])

  return (
    <Container className={className} search={search}>
      <div className='container pt-4 pt-lg-0'>
        <div className='d-flex align-items-start justify-content-between'>
          {
            !isMobile ? (
              <DefaultTab className='tab-blog-articles' items={categoriesJSON}>
                { articleCategory }
              </DefaultTab>
            ) : (
              <DefaultSelect items={categoriesJSON}>
                { articleCategory }
              </DefaultSelect>
            )
          }
          { search && <GeneralSearch styles='position-absolute mt-5' /> }
          {
            !isMobile &&
              <SearchButton onClick={() => setSearch(!search)}>
                {
                  search
                    ? <img src={ExitIcon} alt='Fechar' title='Fechar' />
                    : <img src={SearchIcon} alt='Buscar' title='Buscar' />
                }
              </SearchButton>}
        </div>
      </div>
    </Container>
  )
}

BlogArticleList.defaultProps = {
  className: '',
  showSidebar: false,
}

export default BlogArticleList
