import React, { useState, useEffect } from 'react'

import HeaderCarousel from 'src/components/HeaderCarousel'
import TopNewsItem from './TopNewsItem'

import useWidth from 'src/hooks/useWidth'

import featuredJSON from 'src/assets/data/featured.json'
import categoriesJSON from 'src/assets/data/categories.json'

import { CategoriesJSONProps, FeaturedJSONProps } from './types'

import TopNewsStyle from './style'
import { SubCategory } from 'src/types/Category'

const WIDTH_MD = 768

const TopNews = () => {
  const [ activeNewsSlider, setActiveNewsSlider ] = useState({
    news: 0,
    slider: 0,
  })
  const [ itemSlider, setItemSlider ] = useState<FeaturedJSONProps[][]>()
  const newsPerSlider = 3
  const speedInterval = 5000
  const windowWidth = useWidth(300)

  useEffect(() => {
    function formatData () {
      let counter = 0
      let group = 0
      const groups = []
      for (let i = 0; i < featuredJSON.length; i++) {
        if (counter < newsPerSlider) {
          if (groups[group]) {
            groups[group].push(featuredJSON[i])
          } else {
            groups.push([ featuredJSON[i] ])
          }
        } else {
          counter = 0
          group++
          groups.push([ featuredJSON[i] ])
        }
        counter++
      }
      setItemSlider(groups)
    }

    formatData()
  }, [])

  useEffect(() => {
    function updateActiveNews () {
      if (!itemSlider.length) return
      setActiveNewsSlider((activeNewsSlider: { news: number; slider: number }) => {
        if (activeNewsSlider.news < Math.min(itemSlider[activeNewsSlider.slider].length - 1, newsPerSlider - 1)) {
          return {
            news: activeNewsSlider.news + 1,
            slider: activeNewsSlider.slider,
          }
        } else if (activeNewsSlider.slider < Math.ceil(featuredJSON.length / newsPerSlider) - 1) {
          return {
            news: 0,
            slider: activeNewsSlider.slider + 1,
          }
        } else {
          return {
            news: 0,
            slider: 0,
          }
        }
      })
    }

    if (windowWidth >= WIDTH_MD && itemSlider !== undefined) {
      const newsInterval = setInterval(updateActiveNews, speedInterval)
      return () => clearInterval(newsInterval)
    }
  }, [ windowWidth, itemSlider ])

  if (!itemSlider) {
    return <></>
  }

  function mouseInSlider (news: number, slider: number) {
    setActiveNewsSlider({ news, slider })
  }

  return (
    <TopNewsStyle>
      <HeaderCarousel activeItem={activeNewsSlider.slider}>
        {
          itemSlider.map((group: FeaturedJSONProps[], sliderIndex: number) => (
            <div key={sliderIndex} className='row justify-content-center justify-content-lg-between'>
              <div className='col-10 col-lg-5 col-xl-6 d-flex align-items-center justify-content-center'>
                <img className='d-md-none d-lg-block' src={group[activeNewsSlider.news]?.home_image_url} alt='Banner Home' />
              </div>
              <div className='col-12 col-lg-6 col-xl-5'>
                {
                  group.map((article: FeaturedJSONProps, newsIndex: number) => {
                    const isActive = activeNewsSlider.news === newsIndex
                    const category = categoriesJSON.find((category: CategoriesJSONProps) => article.category_id === category.id)
                    const subCategory = category?.subcategories &&
                    category.subcategories.find((subCategory: SubCategory) => subCategory.id === article.subcategory_id)
                    return (
                      <>
                        <TopNewsItem
                          link={`/${article.slug}/`}
                          key={newsIndex}
                          active={isActive}
                          image={article.home_image_url}
                          category={category?.name as string}
                          subCategory={subCategory?.name}
                          title={article.title}
                          hover={ () => mouseInSlider(newsIndex, activeNewsSlider.slider)}
                        />
                      </>
                    )
                  })
                }
              </div>
            </div>
          ))
        }
      </HeaderCarousel>
    </TopNewsStyle>
  )
}

export default TopNews
